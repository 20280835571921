// Disable the submit button of forms when the form is submitted
document.querySelectorAll('.disable-on-submit').forEach(function(form) {
  form.addEventListener('submit', function() {
    form.querySelectorAll('[type=submit]').forEach(function(btn) {
      btn.disabled = true;
      btn.value = btn.dataset.submitLabel || 'Submitting...';
    });
  });
});

// Enable popper.js tooltips
document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(function(elem) {
  new bootstrap.Tooltip(elem);
});
